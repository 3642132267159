<template>
  <div class="container oci-check-in-out-body">
    <div class="oci-check-in-out-heading-date">
      Please enter a reason:
    </div>
    <br>
    <div>
      <textarea v-model="reason" rows="10" style="width:330px;"></textarea>
    </div>
    <div class="oci-check-in-button-container">
      <b-button
        type="submit"
        @click="UnableToAttend"
      >
        Send
      </b-button>
    </div>
  </div>  
</template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BButton } from 'bootstrap-vue'
import Completed from '@/views/components/Completed.vue'
import { checkInOutMethods } from '@/components/ui/CheckInOutFunctions'

export default {
  components: {
    BImg,
    BButton,
  },
  computed: {
    ...mapGetters({ isLoadingAssigments: 'app/getIsLoadingAssigments' }),
    ...mapGetters({ totalAssignments: 'app/getTotalAssignments' }),
    ...mapGetters({ checkedInStatus: 'app/getCheckedInStatus' }),
    ...mapGetters({ userName: 'app/getUserName' }),
    ...mapGetters({ currentDay: 'app/getCurrentDateHeading' }),
    ...mapGetters({ assignmentID: 'app/getUserAssignmentsID' }),
    ...mapGetters({ assignmentsIDList: 'app/getUserAssignmentsIDList' }),
    ...mapGetters({ signintime: 'app/getSignInTime' }),
    ...mapGetters({ userAssignmentSet: 'app/getUserAssignmentSet' }),
    ...mapGetters({ oneAlreadyCheckedIn: 'app/getOneAlreadyCheckedIn' }),
    ...mapGetters({ sitesList: 'app/getSiteDetailsList' }),
    ...mapGetters({ previousAssignmentsID: 'app/getPreviousAssignmentsID' }),    
    ...mapGetters({ site: 'app/getCurrentSiteDetails' }),
        
    subHeading() {
      return 'You have completed this project'
    },
  },
  data() {
    return {
      jsonData: [],
      reason: '',
      noJobsImg: require('@/assets/images/pages/rafiki.png'),
    }
  },
  created() {
    this.$store.dispatch('app/getCurrentJobForCurrentDay', this.assignmentID)
    return
  },
  methods: {
    formatDate(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];      
      return String(data.day) +' '+ monthNames[data.month-1]+' '+ String(data.year)      
    },   
    UnableToAttend() {
      // console.log('UnableToAttend')
      // console.log(this.assignmentID)
      // console.log(this.reason)
      checkInOutMethods.UnableToAttend(this.assignmentID, this.reason)
      this.$router.push('/projects');
    },     
  },
}
</script>
